/* eslint-disable import/no-cycle */
import { IndexableType } from 'dexie';
import {
  db,
  Transaction,
  Version,
  Inspector,
  Instrument,
  Organization,
  Inspection,
  PendingFile,
  UploadedChunk,
  MultipartChunk,
  QueuedChunk
} from './db';
import { Inspection as InspectionClass } from '../classes/inspection';
import { debouncedSendMessageToSW } from '../shared/utils';
import { InspectionStatus } from '../classes/enums';
import { UploadFile } from '../classes/airmethane-file';
import { CHUNK_SIZE, MULTIPART_THRESHOLD } from '../shared/constants';

const activeStatuses = ['queued', 'processing', 'retrying'];

const ageInMinutes = (now: Date, incomingDate: Date) => (
  (now.getTime() - incomingDate.getTime()) / (1000 * 60)
);

export const deleteUploadedMultipartChunks = async (
  inspectionId: string,
  fileId: string
): Promise<void> => {
  try {
    await db.multipartChunks.where('inspectionId').equals(inspectionId).and((chunk) => (
      chunk.fileId === fileId
    )).delete();
  } catch (error) {
    console.error('Error deleting uploaded chunks:', error);
    throw error;
  }
};

export const patchUploadedMultipartChunk = async (
  InspectionId: string,
  fileId: string,
  uploadedChunk: UploadedChunk
): Promise<MultipartChunk> => {
  try {
    const existingChunk = await db.multipartChunks.where('inspectionId').equals(InspectionId).and((chunk) => (
      chunk.fileId === fileId
    )).and((chunk) => (
      chunk.partNumber === uploadedChunk.partNumber
    ))
      .first();
    if (!existingChunk) {
      throw new Error('Chunk not found');
    }
    existingChunk.etag = uploadedChunk.etag;
    existingChunk.blob = undefined;
    await db.multipartChunks.put(existingChunk);
    return existingChunk;
  } catch (error) {
    console.error('Error patching uploaded chunk:', error);
    throw error;
  }
};

export const getUploadedMultipartChunks = async (
  inspectionId: string,
  fileId: string
): Promise<UploadedChunk[]> => {
  try {
    const uploadedChunks = await db.multipartChunks.where('inspectionId').equals(inspectionId).and((chunk) => (
      chunk.fileId === fileId
    )).toArray();
    return uploadedChunks
      .filter((chunk) => chunk.etag !== undefined)
      .map((chunk) => ({
        partNumber: chunk.partNumber,
        etag: chunk.etag!
      }));
  } catch (error) {
    console.error('Error getting uploaded chunks:', error);
    throw error;
  }
};

export const getQueuedMultipartChunks = async (
  inspectionId: string,
  fileId: string
): Promise<QueuedChunk[]> => {
  try {
    const pendingChunks = await db.multipartChunks.where('inspectionId').equals(inspectionId).and((chunk) => (
      chunk.fileId === fileId
    )).toArray();
    return pendingChunks
      .filter((chunk) => chunk.blob !== undefined)
      .map((chunk) => ({
        partNumber: chunk.partNumber,
        blob: chunk.blob!
      }));
  } catch (error) {
    console.error('Error getting pending chunks:', error);
    throw error;
  }
};

export const addPendingFile = async (
  file: UploadFile,
  inspectionId: string
) => {
  try {
    const numberOfChunks = Math.ceil(file.file.size / CHUNK_SIZE);
    const isMultipart = file.file.size > MULTIPART_THRESHOLD;
    if (isMultipart) {
      for (let i = 0; i < numberOfChunks; i += 1) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.file.size);
        const blob = file.file.slice(start, end) as File;
        db.multipartChunks.add({
          inspectionId,
          fileId: file.id,
          partNumber: i + 1,
          blob
        });
      }
    }

    db.pendingFiles.put({
      inspectionId,
      fileId: file.id,
      isMultipart,
      file
    });
  } catch (error) {
    console.error('Error saving pending files:', error);
    throw error;
  }
};

export const getInspectionPendingFiles = async (inspectionId: string): Promise<UploadFile[]> => {
  try {
    const pendingFiles = await db.pendingFiles.where('inspectionId').equals(inspectionId).toArray();
    return pendingFiles.map((pendingFile) => pendingFile.file);
  } catch (error) {
    console.error('Error getting pending files:', error);
    throw error;
  }
};

export const getPendingFile = async (inspectionId: string, fileId: string): Promise<PendingFile | undefined> => {
  try {
    const foundPendingFile = await db.pendingFiles.where('inspectionId').equals(inspectionId).and((pendingFile) => (
      pendingFile.fileId === fileId
    )).first();
    return foundPendingFile;
  } catch (error) {
    console.error('Error getting pending file:', error);
    throw error;
  }
};

export const removePendingFile = async (inspectionId: string, fileId: string): Promise<void> => {
  try {
    await db.pendingFiles.where('inspectionId').equals(inspectionId).and((pendingFile) => (
      pendingFile.fileId === fileId
    )).delete();
    await deleteUploadedMultipartChunks(inspectionId, fileId);
  } catch (error) {
    console.error('Error removing pending file:', error);
    throw error;
  }
};

export const patchPendingFileUploadId = async (
  inspectionId: string,
  fileId: string,
  uploadId: string
): Promise<void> => {
  try {
    const pendingFile = await getPendingFile(inspectionId, fileId);
    if (!pendingFile) {
      throw new Error('Pending file not found');
    }
    pendingFile.uploadId = uploadId;
    await db.pendingFiles.put(pendingFile);
  } catch (error) {
    console.error('Error patching upload id:', error);
    throw error;
  }
};

export const patchPendingFileKey = async (
  inspectionId: string,
  fileId: string,
  key: string
): Promise<PendingFile> => {
  try {
    const pendingFile = await getPendingFile(inspectionId, fileId);
    if (!pendingFile) {
      throw new Error('Pending file not found');
    }
    pendingFile.key = key;
    await db.pendingFiles.put(pendingFile);
    return pendingFile;
  } catch (error) {
    console.error('Error patching key:', error);
    throw error;
  }
};

export const createVersion = async ({ inspectionId, version }: Version): Promise<Version> => {
  const existingVersion = await db.versions.where('inspectionId').equals(inspectionId).first();
  if (existingVersion) {
    throw new Error('Version already exists');
  }
  await db.versions.add({ inspectionId, version });
  return { inspectionId, version };
};

export const updateVersion = async ({ inspectionId, version }: Version): Promise<Version> => {
  const existingTransaction = await db.versions.where('inspectionId').equals(inspectionId).first();
  const id = existingTransaction?.id ?? null;

  if (!id) throw new Error('Version not found');
  await db.versions.update(id, { version });
  return { inspectionId, version };
};

export const addOrUpdateVersion = async ({
  inspectionId,
  version
}: Version): Promise<number | IndexableType> => {
  const existingVersion = await db.versions.where('inspectionId').equals(inspectionId).first();
  if (existingVersion) {
    return db.versions.update(existingVersion.id!, { version });
  }
  return db.versions.add({ inspectionId, version }) as Promise<number>;
};

export const getVersionByInspectionId = async (
  inspectionId: string
): Promise<Version | undefined> => (
  db.versions.where('inspectionId').equals(inspectionId).first()
);

export const addOrUpdateInspections = async (
  inspection: Inspection
): Promise<number | IndexableType> => {
  const existingInspection = await db.inspections.where('inspectionId').equals(inspection!.inspectionId!).first();
  if (existingInspection) {
    return db.inspections.update(existingInspection.id!, inspection);
  }
  return db.inspections.add(inspection) as Promise<number>;
};

export const createTransaction = async (
  {
    queueId,
    request,
    status = 'queued',
    attempts = 0,
    response
  }: Transaction,
  inspectionInstance?: InspectionClass
): Promise<Transaction> => {
  const id = await db.transactions.add({
    queueId,
    request,
    status,
    attempts,
    createdAt: new Date(),
    response
  });
  const createdTransaction = await db.transactions.get(id);
  if (inspectionInstance) {
    const inspectionDate = inspectionInstance.inspectionDate
      ? new Date(inspectionInstance.inspectionDate).toISOString()
      : '';
    await addOrUpdateInspections({
      ...inspectionInstance,
      inspectionId: inspectionInstance.id,
      inspectionDate,
      status: {
        code: inspectionInstance.status as InspectionStatus
      }
    } as Inspection);
  }

  debouncedSendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });

  return createdTransaction!;
};

export const moveOldRetryingTransactionsToFailed = async (): Promise<void> => {
  const now = new Date();
  const retryingTransactions = await db.transactions.where('status').equals('retrying').toArray();
  const transactionsToFail = retryingTransactions.filter((transaction) => (
    ageInMinutes(now, transaction.processingStartedAt ?? now) > (12 * 60)
  )).map((transaction) => ({
    key: transaction.id!,
    changes: {
      status: 'failed' as keyof Transaction['status'],
      updatedAt: now
    }
  }));
  await db.transactions.bulkUpdate(transactionsToFail);
};

export const deleteOldFailedTransactions = async (): Promise<void> => {
  const now = new Date();
  const sevenDaysInMinutes = 7 * 24 * 60;
  const failedTransactionsToDelete = await db.transactions.where('status')
    .equals('failed').and((transaction) => (
      ageInMinutes(now, transaction.updatedAt ?? now) > sevenDaysInMinutes
    )).toArray();
  await db.transactions.bulkDelete(
    failedTransactionsToDelete.map((transaction) => transaction.id!)
  );
};

export const deleteCompletedTransactions = async (): Promise<void> => {
  const now = new Date();
  const dayInMinutes = 24 * 60;
  const transactionsToDelete = await db.transactions.where('status')
    .equals('completed')
    .and((transaction) => (
      ageInMinutes(now, transaction.completedAt ?? now) > dayInMinutes
    )).toArray();
  await db.transactions.bulkDelete(
    transactionsToDelete.map((transaction) => transaction.id!)
  );
};

export const getAllTransactions = async (): Promise<Transaction[]> => (
  db.transactions.toArray()
);

export const getTransactionsQueueByQueueId = async (queueId: string): Promise<Transaction[]> => {
  const oneDayAgo = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  return db.transactions.where('queueId').equals(queueId).and((transaction) => (
    activeStatuses.includes(transaction.status!)
  ))
    .and((transaction) => (
      (transaction.processingStartedAt! > oneDayAgo) || !transaction.processingStartedAt
    ))
    .toArray();
};

export const getAllTransactionsByQueueId = async (queueId: string): Promise<Transaction[]> => (
  db.transactions.where('queueId').equals(queueId)
    .and((transaction) => activeStatuses.includes(transaction.status ?? '')).toArray()
);

export const getTransactionsByStatus = async (status: Transaction['status']): Promise<Transaction[]> => {
  const transactions = await db.transactions.where('status').equals(status ?? '').toArray();
  return transactions;
};

export const getTransactionById = async (id: number): Promise<Transaction | undefined> => (
  db.transactions.get(id)
);

export const getTransactionsQueue = async (): Promise<Transaction[]> => {
  const oneDayAgo = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  return db.transactions
    .where('status').anyOf(activeStatuses)
    .and((transaction) => (
      transaction.processingStartedAt! > oneDayAgo || !transaction.processingStartedAt))
    .toArray();
};

export const getTransactionQueueIds = async (): Promise<string[]> => {
  const transactionQueues = await db.transactions.where('status').anyOf(activeStatuses).toArray();
  const queuesWithProcessingTransactions = Array.from(
    new Set(transactionQueues.filter((transaction) => (
      transaction.status === 'processing'
    )).map((transaction) => transaction.queueId))
  );
  const uniqueQueueIds = Array.from(
    new Set(transactionQueues.map((transaction) => transaction.queueId))
  ).filter((queueId) => !queuesWithProcessingTransactions.includes(queueId));
  return uniqueQueueIds;
};

export const getAllFailedTransactions = async (): Promise<Transaction[]> => (
  db.transactions.where('status').equals('failed').toArray()
);

export const getAllCompletedTransactions = async (): Promise<Transaction[]> => (
  db.transactions.where('status').equals('completed').toArray()
);

export const getTimedOutOrFailedTransactions = async (): Promise<Transaction[]> => {
  const oneDayAgo = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  return db.transactions
    .where('processingStartedAt').below(oneDayAgo)
    .or('status').equals('failed')
    .toArray();
};

export const updateAuthTokenForPendingTransactions = async (authToken: string): Promise<void> => {
  const pendingAndRetryingTransactions = await db.transactions.where('status').anyOf(['queued', 'retrying']).toArray();
  const updatedTransactions = pendingAndRetryingTransactions.map((transaction) => ({
    key: transaction.id!,
    changes: {
      request: {
        ...transaction.request,
        headers: {
          ...transaction.request.headers,
          Authorization: `Bearer ${authToken}`
        }
      }
    }
  }));
  await db.transactions.bulkUpdate(updatedTransactions);
};

export const updateTransactionStatus = async (id: number, status: Transaction['status']): Promise<Transaction> => {
  const now = new Date();
  const existingTransaction = await db.transactions.get(id);
  await db.transactions.update(id, {
    status,
    updatedAt: now,
    ...(
      status === 'processing'
      && !existingTransaction?.processingStartedAt
      && { processingStartedAt: now }
    ),
    ...(
      status === 'completed'
      && !existingTransaction?.completedAt
      && { completedAt: now }
    )
  });
  const updatedTransaction = await db.transactions.get(id);
  return updatedTransaction!;
};

export const updateTransactionResponse = async (
  id: number,
  response: Transaction['response']
): Promise<Transaction> => {
  await db.transactions.update(id, {
    response,
    updatedAt: new Date()
  });
  const updatedTransaction = await db.transactions.get(id);
  return updatedTransaction!;
};

export const updateTransactionRequest = async (id: number, request: Transaction['request']): Promise<Transaction> => {
  await db.transactions.update(id, {
    request,
    updatedAt: new Date()
  });
  const updatedTransaction = await db.transactions.get(id);
  return updatedTransaction!;
};

export const incrementTransactionAttempts = async (id: number): Promise<Transaction> => {
  const transaction = await db.transactions.get(id);
  if (!transaction) {
    throw new Error('Transaction not found');
  }
  await db.transactions.update(id, {
    attempts: (transaction.attempts ?? 0) + 1,
    updatedAt: new Date()
  });
  const updatedTransaction = await db.transactions.get(id);
  return updatedTransaction!;
};

export const retryTransaction = async (id: number): Promise<Transaction> => {
  const transaction = await db.transactions.get(id);
  if (!transaction) {
    throw new Error('Transaction not found');
  }
  const now = new Date();
  await db.transactions.update(id, {
    status: 'retrying',
    processingStartedAt: now,
    updatedAt: now
  });
  const updatedTransaction = await db.transactions.get(id);
  return updatedTransaction!;
};

export const deleteInspectionTransactions = async (flogistixId: string): Promise<void> => {
  const transactions = await db.transactions
    .where('queueId')
    .startsWith(flogistixId)
    .and((transaction) => ['queued', 'retrying'].includes(transaction.status!))
    .toArray();
  await db.transactions.bulkDelete(transactions.map((transaction) => transaction.id!));
};

export const deleteTransaction = async (id: number): Promise<Transaction> => {
  const transaction = await db.transactions.get(id);
  await db.transactions.delete(id);
  const confirmDelete = await db.transactions.get(id);
  if (confirmDelete) {
    throw new Error('Transaction was not deleted');
  }
  return transaction!;
};

export const addOrUpdateInspectors = async (inspector: Inspector): Promise<number | IndexableType> => {
  const existingInspectors = await db.inspectors
    .where('inspectorId')
    .equals(inspector.inspectorId)
    .first();
  if (existingInspectors) {
    return db.inspectors.update(existingInspectors.id!, { ...inspector });
  }
  return db.inspectors.add(inspector) as Promise<number>;
};

export const addOrUpdateInstruments = async (instrument: Instrument): Promise<number | IndexableType> => {
  const existingInstruments = await db.instruments
    .where('instrumentId')
    .equals(instrument.instrumentId)
    .first();
  if (existingInstruments) {
    return db.instruments.update(existingInstruments.id!, instrument);
  }
  return db.instruments.add(instrument) as Promise<number>;
};

export const addOrUpdateOrgs = async (org: Organization): Promise<number | IndexableType> => {
  const existingOrgs = await db.orgs
    .where('id')
    .equals(org.id!)
    .first();
  if (existingOrgs) {
    return db.orgs.update(existingOrgs.id!, { ...org });
  }
  return db.orgs.add(org) as Promise<number>;
};

export const getLocalOrgByOrgId = async (orgId: string): Promise<Organization | undefined> => (
  db.orgs.where('orgId').equals(orgId).first()
);

export const getLocalInspectionById = async (
  inspectionId: string
): Promise<Inspection | undefined> => (
  db.inspections.where('inspectionId').equals(inspectionId).first()
);

export const deleteLocalInspectionById = async (inspectionId: string): Promise<void> => {
  const existingInspection = await db.inspections.where('inspectionId').equals(inspectionId).first();
  if (existingInspection) {
    await db.inspections.delete(existingInspection.id!);
  }
};

export const retryInProgressTransactions = async (): Promise<void> => {
  const inProgressTransactions = await getTransactionsByStatus('processing');
  inProgressTransactions.forEach((transaction: Transaction) => {
    if (transaction.id !== undefined) {
      updateTransactionStatus(transaction.id, 'retrying');
    }
  });
};

export const clearInspectionsTable = async (): Promise<void> => db.inspections.clear();

export const getAllInspectors = async (): Promise<Inspector[]> => db.inspectors.toArray();

export const getActiveInspectors = async (): Promise<Inspector[]> => {
  const allInspectors = await db.inspectors.toArray();
  return allInspectors.filter((inspector) => inspector.active === true);
};

export const getAllInstruments = async (): Promise<Instrument[]> => db.instruments.toArray();

export const getAllOrgs = async (): Promise<Organization[]> => db.orgs.toArray();

export const getAllInspections = async (): Promise<Inspection[]> => db.inspections.toArray();
