import { useContext, useEffect, useState } from 'react';
import { IoAddOutline } from 'react-icons/io5';
import { Toggle } from '@flogistix/flo-ui';
import { InspectionContext } from '../../../../../context/InspectionContext';
import { Leak } from '../../../../../classes/leak';
import LeakCard from './LeakCard';

import './Leaks.scss';

const Leaks = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext);
  const [leaks, setLeaks] = useState(inspection?.leaks);
  const [hasLeaks, setHasLeaks] = useState((inspection?.leaks?.length ?? 0) > 0);

  const createLeak = (leak?: Leak, index?: number): Leak[] => {
    let newLeak;
    if (leak) {
      newLeak = leak;
    } else {
      newLeak = new Leak({ priority: 'None' });
    }
    const newLeaks = [...(leaks ?? [])];
    newLeaks.splice(index ?? newLeaks.length, 0, newLeak);
    setLeaks(newLeaks);
    if (newLeak.tagNumber !== undefined && newLeak.tagNumber !== '') {
      inspection!.addLeak(token, newLeak).then(() => {
        setHasLeaks(true);
        setCompletedSectionsOnChange();
      });
    }
    return newLeaks;
  };

  const deleteLeak = (leak: Leak, index?: number, currentleaks?: Leak[]): Leak[] => {
    const newLeaks = [...(currentleaks ?? leaks ?? [])];
    newLeaks.splice(index!, 1);
    setLeaks(newLeaks);
    if (leak.tagNumber !== undefined && leak.tagNumber !== '') {
      inspection!.removeLeak(token, leak).then(() => {
        if (newLeaks?.length === 0) setHasLeaks(false);
        setCompletedSectionsOnChange();
      });
    }
    return newLeaks!;
  };

  const handleHasLeaksToggle = () => {
    if (hasLeaks) {
      setHasLeaks(false);
    } else {
      setHasLeaks(true);
    }
  };

  useEffect(() => {
    if (hasLeaks && !inspection!.leaks?.length) {
      createLeak();
    }
  }, [hasLeaks]);

  useEffect(() => {
    if (inspection?.leaks === undefined) {
      inspection!.initializeLeaks();
    }
  }, []);
  return (
    <div className="leaks-container">
      <div className="section-heading">
        <h1 className="page-details__header">Leak details</h1>
        <h4 className="part">Part 1 of 2</h4>
        <button
          className="add-new-item-button"
          type="button"
          onClick={() => createLeak()}
        >
          <IoAddOutline />
          Add new leak
        </button>
      </div>
      <label className="label">Were there any leaks found on site?</label>
      <Toggle
        firstOption="Yes"
        secondOption="No"
        activeOption={hasLeaks ? 'Yes' : 'No'}
        onToggle={handleHasLeaksToggle}
        disabled={(leaks?.length ?? 0) > 0}
      />
      {
        leaks?.map((leak: Leak, i: number) => (
          <LeakCard
            key={leak.tagNumber}
            leakIndex={i}
            leak={leak}
            deleteLeak={
              (editedLeak: Leak, index?: number, currentLeaks?: Leak[]) => deleteLeak(
                editedLeak,
                index ?? i,
                currentLeaks
              )
            }
            createLeak={(editedLeak?: Leak) => createLeak(editedLeak, i)}
            leaks={leaks}
          />
        ))
      }
      {leaks && (leaks.length === 0 || leaks[leaks.length - 1]?.tagNumber) && (
        <button
          className="add-new-item-button-bottom"
          type="button"
          onClick={() => createLeak()}
        >
          <IoAddOutline />
          Add new leak
        </button>
      )}
    </div>
  );
};

export default Leaks;
