import Dexie, { EntityTable } from 'dexie';
// eslint-disable-next-line import/no-cycle
import { Inspection as InspectionClass } from '../classes/inspection';
import { InspectionStatus } from '../classes/enums';
import { UploadFile } from '../classes/airmethane-file';

export type JsonType = {
  [key: string]: string | number | File | object | JsonType | JsonType[];
};

export interface Transaction {
  id?: number;
  sequence?: number;
  queueId: string;
  request: {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    headers: { [key: string]: string };
    payload: JsonType;
    endpoint: string;
    fileUpload?: boolean;
  };
  response?: JsonType;
  status?: 'queued' | 'processing' | 'retrying' | 'failed' | 'completed';
  attempts?: number;
  createdAt?: Date;
  updatedAt?: Date;
  processingStartedAt?: Date;
  completedAt?: Date;
}
interface Credential {
  certificationExpiration: string | null;
  credentials: string;
  licenseNumber: number;
}

interface Site {
  id?: number;
  siteId: number;
  siteName: string;
  netsuiteId: number;
}

export interface Inspector {
  active: boolean;
  id?: number;
  inspectorId: string;
  credentials: Credential[];
  email: string;
  name: string;
}

export interface Instrument {
  id?: number;
  instrumentId: string;
  name: string;
  serialNumber: string;
}

export interface Organization {
  id?: number | string;
  orgId: string;
  orgName: string;
  netsuiteId: string;
  sites: Site[];
}

export interface Version {
  id?: number;
  inspectionId: string;
  version: number;
}

export interface Inspection extends Omit<
InspectionClass,
'id' |
'createdBy' |
'updatedBy' |
'inspectionDate' |
'status'
> {
  id?: number | string;
  inspectionId?: string;
  createdByEmail?: string;
  inspectionDate: string;
  createdByName?: string;
  updatedByEmail?: string;
  updatedByName?: string;
  flogistixId?: string;
  status: {
    code: InspectionStatus;
  };
}

export interface UploadedChunk {
  etag: string;
  partNumber: number;
}

export interface QueuedChunk {
  blob: Blob;
  partNumber: number;
}

export interface PendingFile {
  id?: number;
  fileId: string;
  inspectionId: string;
  file: UploadFile;
  isMultipart: boolean;
  uploadId?: string;
  key?: string;
}

export interface MultipartChunk {
  id?: number;
  fileId: string;
  inspectionId: string;
  etag?: string;
  blob?: Blob;
  partNumber: number;
}

export class TransactionsClass extends Dexie {
  transactions!: EntityTable<Transaction, 'id'>;

  versions!: EntityTable<Version, 'id'>;

  inspectors!: EntityTable<Inspector, 'id'>;

  inspections!: EntityTable<Inspection, 'id'>;

  instruments!: EntityTable<Instrument, 'id'>;

  orgs!: EntityTable<Organization, 'id'>;

  pendingFiles!: EntityTable<PendingFile, 'id'>;

  multipartChunks!: EntityTable<MultipartChunk, 'id'>;

  constructor() {
    super('airMethaneDb');
    this.version(3).stores({
      transactions: `++id, 
                    sequence, 
                    queueId, 
                    request, 
                    response, 
                    status, 
                    attempts, 
                    createdAt, 
                    updatedAt, 
                    processingStartedAt,
                    completedAt `,
      versions: '++id, inspectionId, version',
      inspectors: '++id, inspectorId, name, credentials, email',
      inspections: '++id, inspectionId, createdByName, createdByEmail, flogistixId, status',
      instruments: '++id, instrumentId, name, serialNumber',
      orgs: '++id, orgId, netsuiteId, orgName, sites',
      pendingFiles: '++id, fileId, inspectionId',
      multipartChunks: '++id, fileId, inspectionId, partNumber'
    });
  }
}

export const db = new TransactionsClass();
