/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IoChevronDownOutline, IoChevronUpOutline, IoRemoveCircle } from 'react-icons/io5';
import {
  Button,
  Dropdown,
  DualInput,
  Input,
  Toggle
} from '@flogistix/flo-ui';
import { InspectionContext } from '../../../../../context/InspectionContext';
import { ComponentType } from '../../../../../classes/enums';
import { Leak } from '../../../../../classes/leak';
import AirMethaneFile, { UploadFile } from '../../../../../classes/airmethane-file';
import { DropdownOptionType } from '../../../shared/typings';
import { PRIORITY_OPTIONS } from '../../../../../shared/constants';
import BaseDropzone from '../../../../../components/FileDropzone/BaseDropzone/BaseDropzone';
import FileLabel from '../../../../../components/Shared/FileLabel/FileLabel';
import FormLabel from '../../../../../components/Shared/FormLabel/FormLabel';
import { global } from '../../../../../shared/colors';
import './LeakCard.scss';

interface LeakImage {
  id?: string;
  fileType?: string;
  fileName?: string;
}

const LeakCard = ({
  leak,
  leakIndex,
  leaks,
  deleteLeak,
  createLeak
}: {
  leak: Leak,
  leakIndex: number,
  leaks: Leak[],
  deleteLeak: (leak: Leak, index?: number, leaks?: Leak[]) => Leak[],
  createLeak: (leak?: Leak, index?: number) => Leak[]
}) => {
  const {
    inspection,
    token,
    setCompletedSectionsOnChange,
    fileOptions,
    hasFileId
  } = useContext(InspectionContext);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [leakTagNumber, setTagNumber] = useState(leak?.tagNumber || '');
  const [leakComponentType, setComponentType] = useState(leak?.componentType || undefined);
  const [leakComponentSubtype, setComponentSubtype] = useState(leak?.componentSubtype || '');
  const [leakPriority, setPriority] = useState(leak?.priority || 'None');
  const [leakNotes, setNotes] = useState(leak?.notes || '');
  const [leakLocation, setLocation] = useState(leak?.location || '');
  const [leakTimestamp, setTimestamp] = useState(leak?.timestamp || '');
  const [timeStampMinutes, setTimestampMinutes] = useState(leak?.timestamp?.split(':')[0] || '');
  const [timeStampSeconds, setTimestampSeconds] = useState(leak?.timestamp?.split(':')[1] || '');
  const [leakRate, setRate] = useState(leak?.rate || '');
  const [leakRateUom, setRateUom] = useState(leak?.rateUom || 'ppm');
  const [firstImage, setFirstImage] = useState(leak?.files?.at(0) ?? {} as LeakImage);
  const [secondImage, setSecondImage] = useState(leak?.files?.at(1) ?? {} as LeakImage);
  const [componentTypeSearch, setComponentTypeSearch] = useState('');
  const [prioritySearch, setPrioritySearch] = useState('');
  const [localLeak, setLocalLeak] = useState(leak);
  const [isValid, setIsValid] = useState(leak.tagNumber !== '' && leak.tagNumber !== undefined);
  const [isDuplicateTagNumber, setIsDuplicateTagNumber] = useState(false);

  const handleTagNumberBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const tagNumber = e.target.value.trim();
    setTagNumber(tagNumber);

    const isDuplicate = leaks.some(
      (otherLeak: Leak, index: number) => otherLeak.tagNumber === tagNumber && index !== leakIndex
    );
    setIsDuplicateTagNumber(isDuplicate);

    if (isDuplicate) {
      return;
    }

    if (tagNumber === leak.tagNumber) return;
    if (!isValid) setIsValid(true);

    const newLeak = new Leak({ ...leak, tagNumber });
    setLocalLeak(newLeak);

    const updatedLeaks = createLeak(newLeak, leakIndex);
    deleteLeak(leak, leakIndex + 1, updatedLeaks);
    setCompletedSectionsOnChange();
  };

  const handleComponentTypeChange = (selectedOption: DropdownOptionType) => {
    const componentType = selectedOption.value as ComponentType;
    setComponentType(componentType);
    setLocalLeak(new Leak({ ...localLeak, componentType }));
    if (!isValid || isDuplicateTagNumber) return;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...localLeak,
      componentType
    } as Leak).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const handleComponentSubtypeBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const componentSubtype = e.target.value as string;
    setComponentSubtype(componentSubtype);
    setLocalLeak(new Leak({ ...localLeak, componentSubtype }));
    if (!isValid || isDuplicateTagNumber) return;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...localLeak,
      componentSubtype
    } as Leak).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const handlePriorityChange = (selectedOption: DropdownOptionType) => {
    const priority = selectedOption.value as string;
    setLocalLeak(new Leak({ ...localLeak, priority }));
    setPriority(priority);
    if (!isValid || isDuplicateTagNumber) return;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...localLeak,
      priority
    } as Leak).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const handleNotesBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const notes = e.target.value as string;
    setLocalLeak(new Leak({ ...localLeak, notes }));
    setNotes(notes);
    if (!isValid || isDuplicateTagNumber) return;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...localLeak,
      notes
    } as Leak).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const handleLocationBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const location = e.target.value as string;
    setLocation(location);
    setLocalLeak(new Leak({ ...localLeak, location }));
    setCompletedSectionsOnChange();
    if (!isValid || isDuplicateTagNumber) return;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...localLeak,
      location
    } as Leak).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const handleTimestampMinuteBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(e.target.value, 10) < 0) return;
    const minutes = e.target.value.padStart(2, '0');
    const newTimestamp = `${minutes || '00'}:${timeStampSeconds || '00'}`;
    setTimestamp(newTimestamp);
    setLocalLeak(new Leak({ ...localLeak, timestamp: newTimestamp }));
    setTimestampMinutes(minutes);
    if (!isValid || isDuplicateTagNumber) return;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...localLeak,
      timestamp: newTimestamp
    } as Leak).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const handleTimestampSecondsBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(e.target.value, 10) < 0 || parseInt(e.target.value, 10) > 59) return;
    const seconds = e.target.value.padStart(2, '0');
    const newTimestamp = `${timeStampMinutes || '00'}:${seconds || '00'}`;
    setTimestamp(newTimestamp);
    setTimestampSeconds(seconds);
    setLocalLeak(new Leak({ ...localLeak, timestamp: newTimestamp }));
    if (!isValid || isDuplicateTagNumber) return;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...localLeak,
      timestamp: newTimestamp
    } as Leak).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rate = Number(e.target.value);
    setRate(rate);
    setLocalLeak(new Leak({ ...localLeak, rate }));
    if (!isValid || isDuplicateTagNumber) return;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...localLeak,
      rate
    } as Leak).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const handleRateUomChange = () => {
    const newRateUom = leakRateUom === 'ppm' ? 'mcf' : 'ppm';
    setRateUom(newRateUom);
    setLocalLeak(new Leak({ ...localLeak, rateUom: newRateUom }));
    if (!isValid || isDuplicateTagNumber) return;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...localLeak,
      rateUom: newRateUom
    } as Leak).then(() => {
      setCompletedSectionsOnChange();
    });
  };

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    const pendingFiles: UploadFile[] = [];
    const files = e.type === 'drop'
      ? (e as React.DragEvent<HTMLDivElement>).dataTransfer.files
      : (e as React.ChangeEvent<HTMLInputElement>).target.files;
    const file = files?.[0];

    if (file) {
      const fileObj = { file, id: uuidv4(), displayName: file.name };
      leak.updateFileValue((new AirMethaneFile(fileObj.id)), index - 1);
      pendingFiles.push(fileObj);

      const updatedLeak = {
        ...localLeak,
        files: leak.files,
        pendingFiles: []
      };

      if (isValid && !isDuplicateTagNumber) {
        await inspection?.addFiles(token, [fileObj]);
        await leak.patchLeak(
          token,
          inspection!.flogistixId!,
          inspection!,
          updatedLeak as unknown as Leak
        );
      }
      const newImage = {
        id: fileObj.id,
        fileType: fileObj.file.type,
        fileName: fileObj.file.name
      } as LeakImage;
      if (index === 1) {
        setFirstImage(newImage);
      } else {
        setSecondImage(newImage);
      }
      setLocalLeak(new Leak(updatedLeak));
      setCompletedSectionsOnChange();
    }
  };

  const handleLeakAttachment = async (fileId: string | number, index: number) => {
    const selectedFile = fileOptions.find((file: { value: string | number; }) => file.value === fileId);

    if (selectedFile) {
      try {
        const newFile = new AirMethaneFile(selectedFile.value as string, selectedFile.value);
        leak.updateFileValue(newFile, index - 1);

        const updatedLeak = {
          ...localLeak,
          files: leak.files,
          pendingFiles: []
        };

        if (isValid && !isDuplicateTagNumber) {
          await leak.patchLeak(
            token,
            inspection!.flogistixId!,
            inspection!,
            updatedLeak as unknown as Leak
          );
        }

        const newImage = {
          id: selectedFile.value as string,
          fileName: selectedFile.label,
          fileType: ''
        } as LeakImage;

        if (index === 1) {
          setFirstImage(newImage);
        } else {
          setSecondImage(newImage);
        }
        setLocalLeak(new Leak(updatedLeak));
        setCompletedSectionsOnChange();
      } catch (error) {
        console.error('Error updating leak:', error);
      }
    }
  };

  const removeLeakImage = (fileId: string | undefined) => {
    if (!fileId) return;

    leak.removeFile(
      token,
      inspection!.flogistixId!,
      fileId,
      0,
      inspection!.id!
    ).then(() => {
      if (fileId === firstImage.id) {
        if (secondImage?.id) {
          setFirstImage(secondImage);
          setSecondImage({} as LeakImage);
        } else {
          setFirstImage({} as LeakImage);
        }
      } else if (fileId === secondImage.id) {
        setSecondImage({} as LeakImage);
      }
      setLocalLeak(new Leak({ ...localLeak, files: leak.files }));
      setCompletedSectionsOnChange();
    });
  };

  const detachLeakImage = (fileId: string | undefined) => {
    if (!fileId) return;

    leak.detachFile(
      token,
      inspection!.flogistixId!,
      inspection!.id!,
      fileId
    ).then(() => {
      if (fileId === firstImage.id) {
        if (secondImage?.id) {
          setFirstImage(secondImage);
          setSecondImage({} as LeakImage);
        } else {
          setFirstImage({} as LeakImage);
        }
      } else if (fileId === secondImage.id) {
        setSecondImage({} as LeakImage);
      }
      setLocalLeak(new Leak({ ...localLeak, files: leak.files }));
      setCompletedSectionsOnChange();
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        deleteButtonRef.current
        && !deleteButtonRef.current.contains(event.target as Node)
      ) {
        setShowDelete(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="delete-button-wrapper" ref={deleteButtonRef}>
      {showDelete ? (
        <Button
          variation="red-outline"
          className="delete-button-container"
          size="medium"
          type="button"
          onClick={() => deleteLeak(localLeak)}
        >
          Delete
        </Button>
      ) : (
        <button
          className="icon-button"
          type="button"
          onClick={() => setShowDelete(true)}
          aria-label="Remove item"
        >
          <IoRemoveCircle className="remove-icon" size={24} />
        </button>
      )}
      <div className={`leak-card-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
        <div className="header-wrapper">
          <div className="repair-header">
            <h5 className="repair-header">{`Leak ${leakIndex + 1}`}</h5>
            <button
              type="button"
              className="toggle-button"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? (
                <IoChevronUpOutline style={{ color: global.Gray4 }} />
              ) : (
                <IoChevronDownOutline style={{ color: global.Gray4 }} />
              )}
            </button>
          </div>
        </div>
        {isExpanded && (
          <div className="fields-container">
            <FormLabel>Leak ID/Tag</FormLabel>
            <Input
              type="text"
              name="tagNumber"
              defaultValue={leakTagNumber}
              onBlur={handleTagNumberBlur}
              placeholder="Enter leak ID"
              className={`input__text ${isDuplicateTagNumber ? 'input-error' : ''}`}
              fixedWidth="100%"
              variation={isDuplicateTagNumber ? 'error' : undefined}
            />
            {isDuplicateTagNumber && (
              <p className="error-message">The tag number you entered is already in use.</p>
            )}
            <FormLabel>Component type</FormLabel>
            <Dropdown
              value={leakComponentType as string}
              id="component-type"
              onSelect={handleComponentTypeChange}
              className="input__select"
              options={Object.values(ComponentType).map(
                (type) => ({ value: type, label: type })
              ).filter(
                (type) => !componentTypeSearch
                  || type.label.toLowerCase().includes(componentTypeSearch.toLowerCase())
              )}
              placeholder="Select type"
              searchValue={componentTypeSearch}
              onSearchChange={(value) => setComponentTypeSearch(value)}
              fixedWidth="100%"
              disabled={!leakTagNumber}
            />
            <FormLabel>Sub-component</FormLabel>
            <Input
              type="text"
              name="componentSubtype"
              defaultValue={leakComponentSubtype}
              onBlur={handleComponentSubtypeBlur}
              placeholder="Enter sub-component"
              className="input__text"
              fixedWidth="100%"
              disabled={!leakTagNumber}
            />
            <FormLabel subLabel="The timestamp in the video this leak occurs">Timestamp</FormLabel>
            <DualInput
              containerClass="timestamp-input"
              width={500}
              disabled={!leakTagNumber}
              leftInput={{
                onBlur: handleTimestampMinuteBlur,
                placeholder: 'Enter amount',
                suffix: 'minutes',
                type: 'number',
                defaultValue: timeStampMinutes,
                maxLength: 2
              }}
              rightInput={{
                onBlur: handleTimestampSecondsBlur,
                placeholder: 'Enter amount',
                suffix: 'seconds',
                type: 'number',
                defaultValue: timeStampSeconds,
                maxLength: 2
              }}
            />
            <FormLabel>Location</FormLabel>
            <Input
              type="text"
              defaultValue={leakLocation}
              onBlur={handleLocationBlur}
              placeholder="Enter location"
              className="input__text"
              fixedWidth="100%"
              disabled={!leakTagNumber}
            />
            <FormLabel>Leak measurement</FormLabel>
            <Toggle
              className="rate-toggle"
              firstOption="PPM.M"
              secondOption="MCF"
              activeOption={leakRateUom === 'mcf' ? 'MCF' : 'PPM.M'}
              onToggle={handleRateUomChange}
              disabled={leakRateUom === undefined || !leakTagNumber}
            />
            <FormLabel>
              {leakRateUom === 'mcf' ? 'MCF' : 'PPM.M'}
            </FormLabel>
            <Input
              type="number"
              defaultValue={leakRate}
              onBlur={handleRateChange}
              placeholder="Enter amount"
              className="input__text"
              fixedWidth="100%"
              disabled={!leakTagNumber}
            />
            <FormLabel>Priority</FormLabel>
            <Dropdown
              value={leakPriority}
              onSelect={handlePriorityChange}
              className="input__select"
              options={PRIORITY_OPTIONS.filter(
                (option) => !prioritySearch || option.label.toLowerCase().includes(prioritySearch.toLowerCase())
              )}
              placeholder="Select priority"
              fixedWidth="100%"
              searchValue={prioritySearch}
              onSearchChange={(value) => setPrioritySearch(value)}
              disabled={!leakTagNumber}
            />
            <FormLabel>Leak summary</FormLabel>
            <div className="leak-summary">
              <div className="leak-summary__header">
                <h5>{leakComponentType}</h5>
                <p>{leakTimestamp}</p>
              </div>
              <div className="leak-summary__content">
                <p>{leakComponentSubtype}</p>
                <p>{leakRateUom}</p>
              </div>
              <p className="leak-summary__location">{leakLocation}</p>
            </div>
            <FileLabel acceptedTypes=".jpg, .png, or .heic">Leak images</FileLabel>
            <div className="leak-images">
              <BaseDropzone
                onFileChange={(e) => handleFileChange(e, 1)}
                acceptedTypes=".png, .jpg, .jpeg, .heic"
                removeHandler={() => removeLeakImage(firstImage?.id)}
                detachHandler={() => detachLeakImage(firstImage?.id)}
                showRemoveButton={!!firstImage?.id}
                fileId={firstImage?.id}
                selectOptions={
                  fileOptions.length > 0 ? fileOptions.filter((option) => option.value !== secondImage?.id) : undefined
                }
                onSelectChange={(fileId) => handleLeakAttachment(fileId, 1)}
                deleteFileDisabled={hasFileId(firstImage?.id as string) || !hasFileId(secondImage?.id as string)}
                disabled={!leakTagNumber}
              />
              <BaseDropzone
                onFileChange={(e) => handleFileChange(e, 2)}
                acceptedTypes=".png, .jpg, .jpeg, .heic"
                removeHandler={() => removeLeakImage(secondImage?.id)}
                detachHandler={() => detachLeakImage(secondImage?.id)}
                showRemoveButton={!!secondImage?.id}
                fileId={secondImage?.id}
                selectOptions={
                  fileOptions.length > 0 ? fileOptions.filter((option) => option.value !== firstImage?.id) : undefined
                }
                onSelectChange={(fileId) => handleLeakAttachment(fileId, 2)}
                deleteFileDisabled={hasFileId(secondImage?.id as string)}
                disabled={firstImage?.id === undefined || !leakTagNumber}
              />
            </div>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              defaultValue={leakNotes}
              onBlur={handleNotesBlur}
              placeholder="Enter description"
              className="input__text"
              fixedWidth="100%"
              disabled={!leakTagNumber}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LeakCard;
