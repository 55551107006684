/* eslint-disable max-len */

import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { urlPrefix } from './url';
import { requiredFieldsBySection, INFINITE, READABLE_MAX_FILE_SIZE } from './constants';

export const fileAssociations = {
  LOCATION_SIGN: 'location-sign-file',
  INSPECTION: 'inspection',
  SURVEY: 'Survey',
  LEAK: 'Leak',
  LEAK_REPAIR: 'LeakRepair',
  VERIFICATION: 'Verification'
};

export const getFilesFromInspection = (inspection) => {
  if (!inspection) return [];
  const totalFiles = [];
  if (inspection.locationSignFile?.id) {
    totalFiles.push({ ...inspection.locationSignFile, association: fileAssociations.LOCATION_SIGN });
  }
  if (inspection.files?.length) {
    const inspectionFiles = inspection.files.map((file) => ({ ...file, association: fileAssociations.INSPECTION }));
    totalFiles.push(...inspectionFiles);
  }
  inspection.surveys?.forEach((survey) => {
    if (survey?.files?.length) {
      const surveyFiles = survey.files.map((file) => ({ ...file, association: fileAssociations.SURVEY }));
      totalFiles.push(...surveyFiles);
    }
  });
  inspection.leaks?.forEach((leak) => {
    if (leak?.files?.length) {
      const leakFiles = leak.files.map((file) => ({ ...file, association: fileAssociations.LEAK }));
      totalFiles.push(...leakFiles);
    }
    leak.leakRepairs?.forEach((repair) => {
      if (repair.files?.length) {
        const repairFiles = repair.files.map((file) => ({ ...file, association: fileAssociations.LEAK_REPAIR }));
        totalFiles.push(...repairFiles);
      }
    });
  });
  inspection.verifications?.forEach((verification) => {
    if (verification?.file) {
      totalFiles.push({ ...verification.file, association: fileAssociations.VERIFICATION });
    }
  });
  return totalFiles;
};

export const getMultipleFilesById = async ({
  fileIds,
  token
}) => {
  const filePromises = fileIds.map((fileId) => (
    fetch(`https://${urlPrefix}files.api.axil.ai/files/${fileId}/presigned-url/inline/true`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    })
  ));
  const fileResults = await Promise.all(filePromises);
  const fileData = await Promise.all(fileResults.map((res) => res.json()));
  const filesWithDisplayInfo = fileData.map((file) => {
    if (file.detail) {
      const { detail } = file;
      const id = detail.split('id ').at(-1);
      return {
        id,
        displayName: 'File not found',
        videoName: 'File not found',
        preview: 'not found',
        version: 'not found'
      };
    }
    return ({
      ...file,
      displayName: file.displayName,
      videoName: file.displayName,
      preview: file.url
    });
  });
  return filesWithDisplayInfo;
};

export const addKeys = (arr = [], key = 'id') => arr.map((item) => ({
  ...item,
  key: key !== false ? item[key] : uuid()
}));

export const makeColumnFilters = (arr, key) => {
  const uniqueValues = [
    ...new Set(arr.map((i) => R.propOr(null, key, i)).filter((i) => i !== null))
  ].sort();
  return uniqueValues.map((value) => ({ text: value, value }));
};

export const groupRecordsBy = (arr, path) => arr.reduce((acc, obj) => {
  const siteId = R.pathOr(null, path, obj)?.id;
  if (siteId) {
    acc[siteId] = (acc[siteId] || 0) + 1;
  }
  return acc;
}, {});

export const createOptionFromTypeSet = (typeSet) => Object.entries(typeSet).map(([, optionType]) => ({
  value: optionType,
  label: optionType.label,
  optionType
}));

const generateUniqueIdsArray = (length) => {
  const result = [];
  const usedIds = new Set();

  while (result.length < length) {
    const newId = Math.floor(Math.random() * 1000);
    if (!usedIds.has(newId)) {
      result.push({ key: newId });
      usedIds.add(newId);
    }
  }

  return result;
};

export const mappingTableRowFixture = generateUniqueIdsArray(8);
export const tableRowFixture = generateUniqueIdsArray(30);

export const convertOptionsStructure = (data) => data.map((item) => ({
  value: item.id,
  label: item.serialNumber ? `${item.name} - ${item.serialNumber}` : item.name,
  item
}));

export const convertEnumerationStructure = (data) => data.map((item, index) => ({
  value: index + 1,
  label: item
}));

export const handleDataLoading = (data, isLoading, error, setOptions, dataKey) => {
  if (!isLoading) {
    if (error) {
      console.error(`Error loading ${dataKey}:`, error);
    }
    if (data) {
      setOptions(convertEnumerationStructure(data));
    }
  }
};

export const handleOptionsLoading = (data, isLoading, error, setOptions, dataKey) => {
  if (!isLoading) {
    if (error) {
      console.error(`Error loading ${dataKey}:`, error);
    }
    if (data) {
      setOptions(convertOptionsStructure(data));
    }
  }
};

export const sendMessageToSW = (message) => {
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(message);
    return true;
  }
  console.error('No active service worker found to send a message.');
  return false;
};

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const debouncedSendMessageToSW = debounce(
  (message) => sendMessageToSW(message),
  500 // 500 miliseconds
);

export const formatDate = (date) => {
  if (!date) return '';
  const [year, month, day] = new Date(date).toISOString().split('T')[0].split('-');
  return `${year}-${month}-${day}`;
};

export const determineSectionCompletion = (section, sectionData) => {
  const requiredFields = requiredFieldsBySection[section];
  for (let i = 0; i < requiredFields.length; i += 1) {
    const field = requiredFields[i];
    if (!sectionData[field]) return false;
  }
  return true;
};

export const triggerMaxFileSizeNotification = (triggerFunc, fileNames) => {
  const params = {
    type: 'warning',
    // eslint-disable-next-line max-len
    message: `The following files exceed the maximum size limit of ${READABLE_MAX_FILE_SIZE}. Please upload smaller files or compress them before trying again. Files: ${fileNames}`,
    customTitle: 'File too large to upload',
    duration: INFINITE
  };

  triggerFunc(params);
};
