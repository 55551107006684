import React, {
  useState, useContext, useEffect, useRef
} from 'react';
import { HiOutlineTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { RxDownload } from 'react-icons/rx';
import { Button, Modal } from '@flogistix/flo-ui';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Tooltip, notification } from 'antd';
import { IoChevronForward } from 'react-icons/io5';
import { v4 as uuidv4 } from 'uuid';

import { RiAddFill, RiUpload2Line } from 'react-icons/ri';
import { Table } from '../../components';
import IconToggle from '../../components/IconToggle';
import { addKeys, getFilesFromInspection } from '../../shared/utils';
import { GlobalContext } from '../../context/GlobalContext';
import { InspectionContext } from '../../context/InspectionContext';
import useFiles from '../../services/useFiles';
import InspectionDeleteModal from '../../components/Modals/InspectionDeleteModal';
import DatabooksModal from '../../components/Modals/DatabooksModal';
import { global } from '../../shared/colors';
import sendDatabookRequest from '../../services/sendDatabookRequest';
import { Inspection } from '../../classes/inspection';
import deleteInspection from '../../services/deleteInspectionNew';
import Status from '../../components/Status/Status';
import { IconWrapper } from './styled-components';
import './InspectionTable.scss';

const timeZone = 'UTC';

const columns = [
  {
    title: 'Identifier',
    dataIndex: 'flogistixId',
    key: 'flogistixId',
    width: '40%',
    sortable: true
  },
  {
    title: 'Created by',
    dataIndex: 'createdByName',
    key: 'createdByName',
    width: '20%',
    sortable: true
  },
  {
    title: 'File count',
    dataIndex: 'filesCount',
    key: 'filesCount',
    width: '20%',
    sortable: true,
    render: (_, record) => `${getFilesFromInspection(record).length}`
  },
  {
    title: 'Inspection date',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    render: (inspectionDate) => {
      const dateAsUTC = utcToZonedTime(new Date(inspectionDate), timeZone);
      return format(dateAsUTC, 'MMM dd, yyyy', { timeZone });
    },
    width: '20%',
    sortable: true
  }
];

const InspectionTable = ({
  inspections,
  user_organization,
  user_site,
  onOrgBreadcrumbClick,
  onInspectionBreadcrumbClick,
  history
}) => {
  const { token } = useContext(InspectionContext) || {};
  const [localInspections, setLocalInspections] = useState(inspections || []);
  const [selectedInspection, setSelectedInspection] = useState({});
  const [expandedInspectionId, setExpandedInspectionId] = useState(null);
  const [isDeleteInspectionModalVisible, setIsDeleteInspectionModalVisible] = useState(false);
  const [isDatabooksModalVisible, setIsDatabooksModalVisible] = useState(false);
  const [inspectionRowUpdateIsPending, setInspectionRowUpdateIsPending] = useState(false);
  const [isReopenModalVisible, setIsReopenModalVisible] = useState(false);
  const orgSiteUrlSegment = `orgs/${user_organization?.orgId}/sites/${user_site?.siteId}`;
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_admin,
    inspectionsReloadIsPending,
    getAndSetInspections,
    setInspId,
    setSelectInspection,
    setSideNavVisibility,
    triggerGlobalNotification
  } = useContext(GlobalContext);
  const [filesLoading, fetchFiles] = useFiles(
    `/files/${orgSiteUrlSegment}?inspectionId=${expandedInspectionId}`
  );

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      try {
        const { inspectionReport } = selectedInspection;
        const inspection = new Inspection(selectedInspection);
        const newInspectionReport = { id: uuidv4(), file, displayName: file.name };

        if (inspectionReport?.id) {
          await inspection.removeFile(token, inspectionReport.id);
        }
        await inspection.addInspectionReport(token, newInspectionReport);

        const index = localInspections?.length
          ? localInspections.findIndex((item) => item.id === inspection?.id)
          : -1;

        let newInspections = localInspections.filter((i) => i.id !== inspection?.id);

        if (index === -1) {
          newInspections = newInspections ? [...newInspections, inspection] : [inspection];
        } else {
          newInspections = newInspections ? [
            ...newInspections.slice(0, index),
            inspection,
            ...newInspections.slice(index)
          ] : [inspection];
        }

        setLocalInspections(newInspections);

        triggerGlobalNotification({
          type: 'success',
          message: 'Upload started successfully.'
        });
      } catch (error) {
        console.error('File upload failed:', error);
      }
    } else {
      console.warn('Only PDF files are allowed.');
    }
  };

  const handleUploadClick = (record) => {
    setSelectedInspection(record);
    fileInputRef.current.click();
  };

  const handleRows = (record) => {
    try {
      if (
        !record
        || !record.id
        || !record.org.id
        || !record.site.id
        || !record.flogistixId
      ) {
        throw new Error('Invalid record or missing properties');
      }

      setInspId(record.id);
      setSelectInspection(record);
      history(
        `/orgs/${record.org.id}/inspections/${record.site.id}/files/${record.flogistixId}/${record.id}`
      );
    } catch (error) {
      console.error('Error handling rows:', error.message);
      notification.error({
        message: 'An error occurred while handling rows',
        description: error.message,
        key: 'handle_rows_error',
        duration: 8
      });
    }
  };

  const refreshFiles = async () => {
    if (!expandedInspectionId) {
      return;
    }

    await fetchFiles();
    setInspectionRowUpdateIsPending(false);
  };

  const handleDelete = async (inspectionRecord) => {
    const inspectionId = inspectionRecord?.inspectionId || inspectionRecord?.id;

    if (!inspectionId) {
      triggerGlobalNotification('error', 'Invalid inspection ID');
      return;
    }

    if (inspectionId === expandedInspectionId) {
      setExpandedInspectionId(null);
    }

    setInspectionRowUpdateIsPending(true);

    try {
      await deleteInspection(token, inspectionId);

      triggerGlobalNotification({
        type: 'success',
        message: 'Inspection has been successfully deleted.',
        fixedWidth: '60%'
      });
    } catch (error) {
      triggerGlobalNotification({
        type: 'error',
        message: `Inspection failed to delete. ${error.message}`,
        fixedWidth: '60%'
      });
    } finally {
      setInspectionRowUpdateIsPending(false);
      setIsDeleteInspectionModalVisible(false);
      await getAndSetInspections();
    }
  };

  const showDeleteInspectionModal = (record) => {
    setSelectedInspection(record);
    setIsDeleteInspectionModalVisible(true);
  };

  const handleDeleteInspectionModalOk = async () => {
    await handleDelete(selectedInspection);
  };

  const handleDeleteInspectionModalCancel = () => {
    setIsDeleteInspectionModalVisible(false);
  };

  const handleDatabooksModalCancel = () => {
    setIsDatabooksModalVisible(false);
  };

  const handleDatabookClick = async (insp) => {
    await sendDatabookRequest(
      insp,
      token,
      user_organization,
      user_site
    );
  };

  const handleDatabooksModalOk = async (record) => {
    await handleDatabookClick(record);
    setIsDatabooksModalVisible(false);
  };

  const showDatabooksModal = (record) => {
    setSelectedInspection(record);
    setIsDatabooksModalVisible(true);
  };

  const handleReopen = (record) => {
    setSelectedInspection(record);
    setIsReopenModalVisible(true);
  };

  const handleReopenModalOk = async () => {
    try {
      const { id, flogistixId } = selectedInspection;
      const localInspection = new Inspection({ id, flogistixId });

      if (!localInspection) {
        throw new Error('Inspection data could not be loaded.');
      }

      localInspection.reopenInspection(token);
      navigate(`/inspections/${selectedInspection?.id}/site-details/1`);
    } catch (error) {
      console.error('Error reopening inspection:', error);
      triggerGlobalNotification('error', 'Failed to reopen inspection');
    } finally {
      setIsReopenModalVisible(false);
    }
  };

  const handleReopenModalCancel = () => {
    setIsReopenModalVisible(false);
  };

  const clientColumns = [
    ...columns,
    {
      title: '',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      no_sort: true,
      width: '20%',
      render: (_, record) => {
        const totalFiles = getFilesFromInspection(record);
        return (
          <IconWrapper>
            <IconToggle
              disabled={!(totalFiles.length > 0)}
              aria-label={
                totalFiles.length > 0
                  ? 'Download Databooks'
                  : 'No Files to Download'
              }
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedInspection(record);
                showDatabooksModal(record);
              }}
            >
              <Tooltip
                title={
                  totalFiles.length > 0
                    ? 'Download Databooks'
                    : 'No Files to Download'
                }
                color={global.White}
              >
                <RxDownload size={16} />
              </Tooltip>
            </IconToggle>
          </IconWrapper>
        );
      }
    }
  ];

  const adminColumns = [
    {
      title: 'Identifier',
      dataIndex: 'flogistixId',
      key: 'flogistixId',
      width: '20%',
      sortable: true
    },
    {
      title: 'Created by',
      dataIndex: 'createdByName',
      key: 'createdByName',
      width: '20%',
      sortable: true
    },
    {
      title: 'File count',
      dataIndex: 'filesCount',
      key: 'filesCount',
      width: '20%',
      sortable: true,
      render: (_, record) => `${getFilesFromInspection(record).length}`
    },
    {
      title: 'Inspection date',
      dataIndex: 'inspectionDate',
      key: 'inspectionDate',
      render: (inspectionDate) => {
        const dateAsUTC = utcToZonedTime(new Date(inspectionDate), timeZone);
        return format(dateAsUTC, 'MMM dd, yyyy', { timeZone });
      },
      width: '20%',
      sortable: true
    },
    {
      title: '',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      no_sort: true,
      width: '20%',
      render: (_, record) => {
        const totalFiles = getFilesFromInspection(record);
        return (
          <section className="action-wrapper">
            <Button
              variation="shadow-outline"
              size="small"
              rounded
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleReopen(record);
              }}
              className="shadow-outline-button"
            >
              Reopen
            </Button>
            <div style={{ display: 'flex', gap: '5px' }}>
              <IconToggle
                aria-label="Upload PDF"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleUploadClick(record);
                }}
              >
                <Tooltip title="Upload PDF" color={global.White}>
                  <RiUpload2Line size={16} />
                </Tooltip>
              </IconToggle>
              <IconToggle
                disabled={!(totalFiles.length > 0)}
                aria-label={
                  totalFiles.length > 0
                    ? 'Download Databooks'
                    : 'No Files to Download'
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedInspection(record);
                  showDatabooksModal(record);
                }}
              >
                <Tooltip
                  title={
                    totalFiles.length > 0
                      ? 'Download Databooks'
                      : 'No Files to Download'
                  }
                  color={global.White}
                >
                  <RxDownload size={16} />
                </Tooltip>
              </IconToggle>
              <IconToggle
                aria-label="Delete Inspection"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  showDeleteInspectionModal(record);
                }}
              >
                <Tooltip title="Delete Inspection" color={global.White}>
                  <HiOutlineTrash size={16} data-cy="Delete Inspection" />
                </Tooltip>
              </IconToggle>
            </div>
          </section>
        );
      }
    }
  ];

  useEffect(() => {
    refreshFiles();
  }, [expandedInspectionId]);

  useEffect(() => {
    if (inspectionsReloadIsPending) {
      refreshFiles();
    }
  }, [inspectionsReloadIsPending]);

  const renderModals = () => (
    <>
      <DatabooksModal
        isVisible={isDatabooksModalVisible}
        inspection={selectedInspection}
        onCancel={handleDatabooksModalCancel}
        onOk={handleDatabooksModalOk}
      />
      <InspectionDeleteModal
        isVisible={isDeleteInspectionModalVisible}
        inspection={selectedInspection}
        onCancel={handleDeleteInspectionModalCancel}
        onOk={handleDeleteInspectionModalOk}
      />
      <Modal
        isOpen={isReopenModalVisible}
        onClose={handleReopenModalCancel}
        title="Are you sure you want to reopen this inspection?"
        subtitle="Reopening will permanently delete the initial report generated for this inspection."
        actions={{
          onCancel: handleReopenModalCancel,
          onConfirm: handleReopenModalOk
        }}
        className="reopen-modal"
        variant="confirm"
      />
    </>
  );

  return (
    <>
      {renderModals()}
      <div className="header-wrapper">
        <section className="header-section">
          <div>
            <div className="table-header">{user_organization?.orgName}</div>
            <Status />
          </div>
          {is_admin && (
            <div className="button-wrapper">
              <Button
                type="text"
                data-cy="CreateInspection"
                onClick={() => {
                  history('/inspections');
                  setSideNavVisibility(false);
                }}
              >
                <RiAddFill
                  size={16}
                  style={{ marginRight: '4px', verticalAlign: 'text-top' }}
                />
                Create new inspection
              </Button>
            </div>
          )}
        </section>
        <div className="breadcrumbs">
          <button
            type="button"
            className="inspection-breadcrumb"
            id="InspectionsBreadcrumb"
            onClick={onInspectionBreadcrumbClick}
            aria-label="Inspections"
          >
            Inspections
          </button>
          <IoChevronForward className="breadcrumb-chevron" />
          <button
            type="button"
            id="OrgBreadcrumbInspections"
            onClick={onOrgBreadcrumbClick}
            aria-label="Inspection"
            className="breadcrumb"
          >
            {user_organization?.orgName}
          </button>
          {user_site && (
            <>
              <IoChevronForward className="breadcrumb-chevron" />
              <div className="site-link">{user_site?.siteName}</div>
            </>
          )}
        </div>
      </div>
      <input
        type="file"
        accept=".pdf"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelect}
      />
      <Table
        loading={
          inspectionsReloadIsPending
          || inspectionRowUpdateIsPending
          || filesLoading
        }
        data={addKeys(localInspections)}
        onRow={handleRows}
        columns={is_admin ? adminColumns : clientColumns}
      />
    </>
  );
};

export default InspectionTable;
