import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Tooltip } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

import { Icon } from '@flogistix/flo-ui';
import { sendMessageToSW } from '../../shared/utils';
import { GlobalContext } from '../../context/GlobalContext';
import airMethaneLogo from '../../assets/FlogistixLogo.png';
import flogistixLogo from '../../assets/HeaderLogo.svg';

import './Header.scss';

const StyledHeaderLogo = styled.a`
    margin-top: 23px;
    margin-left: 40px;
`;

const Logo = () => (
  <StyledHeaderLogo href="/" aria-label="Home">
    <img src={flogistixLogo} alt="Air Methane logo" id="Air Methane logo" />
  </StyledHeaderLogo>
);

const Header = () => {
  const { user } = useAuth0();

  const { setProfileModalOpen } = useContext(GlobalContext);

  const [serviceWorkerActive, setServiceWorkerActive] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setServiceWorkerActive(sendMessageToSW({ type: 'KEEP_ALIVE' }));
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <header role="banner" id="AccountSettingsProfile" className="am-page-header">
      <Logo id="#DashboardLogo" />
      <div className="am-page-header--right-elements">
        <Tooltip
          arrow={false}
          title={(
            <>
              <span className="service-worker-status--title">
                Service Worker Status:
                {' '}
                {serviceWorkerActive ? 'Online' : 'Offline'}
              </span>
              <span className={`service-worker-status--dot${serviceWorkerActive ? '' : ' orange'}`}> •</span>
              <span className="service-worker-status--sub-title">
                {serviceWorkerActive
                  ? 'Your uploads and transactions are processing as expected.'
                  : 'Your uploads and transactions will be delayed.\n\n'
                  + 'Try closing all AirMethane tabs & reopening the page.'}
              </span>
            </>
          )}
          placement="leftBottom"
        >
          <span>
            <Icon
              className={`service-worker-status--icon-indicator${serviceWorkerActive ? '' : ' orange'}`}
              iconName="Info"
            />
          </span>
        </Tooltip>
        <div
          className="am-page-header--profile-container"
          onClick={() => setProfileModalOpen(true)}
          onKeyDown={() => setProfileModalOpen(true)}
          role="button"
          aria-label="Profile and Settings"
          tabIndex={0}
        >
          <button className="am-page-header--profile-button" aria-label="Open Profile Settings" type="button">
            <img src={airMethaneLogo} alt="Flogistix logo" id="Flogistix logo" />
            <Avatar
              size={28}
              src={user?.picture}
              alt={`${user?.name}'s profile picture`}
            />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
