import React from 'react';
import './FormLabel.scss';

const FormLabel = ({
  subLabel,
  htmlFor,
  children
}: {
  subLabel?: string,
  htmlFor?: string,
  children?: React.ReactNode
}) => (
  <>
    <label htmlFor={htmlFor} className="form-label">{children}</label>
    {subLabel && (<label className="form-sub-label">{subLabel}</label>)}
  </>
);

FormLabel.defaultProps = {
  subLabel: undefined,
  htmlFor: undefined,
  children: undefined
};

export default FormLabel;
