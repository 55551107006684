import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IoAddOutline } from 'react-icons/io5';
import { Toggle } from '@flogistix/flo-ui';

import FormLabel from '../../../../../components/Shared/FormLabel/FormLabel';
import { InspectionContext } from '../../../../../context/InspectionContext';
import { Leak } from '../../../../../classes/leak';
import LeakRepair from '../../../../../classes/leak-repair';
import LeakRepairCard from './LeakRepairCard';

const LeakRepairs = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext);
  const [leaksRepaired, setLeaksRepaired] = useState(false);
  const [leakRepairs, setLeakRepairs] = useState<LeakRepair[]>(
    inspection?.leaks?.reduce((listOfRepairs: LeakRepair[], leak: Leak) => (
      [...listOfRepairs, ...leak?.leakRepairs?.map((repair) => (new LeakRepair({
        ...repair,
        tagNumber: leak!.tagNumber!,
        created: true
      }))) ?? []]
    ), []) || []
  );
  const [leakTagOptions, setLeakTagOptions] = useState([] as string[]);

  const createLeakRepair = () => {
    const newLeakRepair = new LeakRepair({
      leakRepairId: uuidv4(),
      date: new Date(),
      created: false,
      tagNumber: leakTagOptions[0] ?? ''
    });
    if (newLeakRepair.tagNumber) {
      const leakOfRepair = inspection!.leaks!.find((leak) => leak.tagNumber === newLeakRepair.tagNumber);
      leakOfRepair!.addRepair(
        token,
        inspection!,
        inspection!.flogistixId!,
        newLeakRepair
      );
      newLeakRepair.updateCreated(true);
    }
    setLeakRepairs([
      ...leakRepairs,
      newLeakRepair
    ]);
    setCompletedSectionsOnChange();
  };

  const deleteLeakRepair = (leakRepair: LeakRepair) => {
    const { tagNumber, ...repairToRemove } = leakRepair;
    delete repairToRemove.created;
    const leakOfRepair = inspection!.leaks?.find((leak) => leak.tagNumber === tagNumber);
    if (leakOfRepair && leakRepair.created) {
      leakOfRepair?.removeRepair(
        token,
        inspection!.flogistixId!,
        inspection!,
        repairToRemove as LeakRepair
      );
    }
    const updatedRepairs = leakRepairs.filter((repair) => repair.leakRepairId !== repairToRemove.leakRepairId);
    setLeakRepairs(updatedRepairs);
    setCompletedSectionsOnChange();
  };

  useEffect(() => {
    if (leakRepairs.length && !leaksRepaired) setLeaksRepaired(true);
    if (leaksRepaired && !leakRepairs.length) setLeaksRepaired(false);
  }, [leakRepairs.length, leaksRepaired]);

  useEffect(() => {
    const newTagOptions = inspection!.leaks?.map((leak) => leak.tagNumber!) ?? [];
    setLeakTagOptions(newTagOptions);
  }, [inspection!.leaks]);

  const handleToggle = () => {
    const newState = !leaksRepaired;
    if (newState && !leakRepairs.length) {
      createLeakRepair();
    }
    setLeaksRepaired(newState);
  };

  return (
    <div className="leaks-container">
      <div className="section-heading">
        <h1 className="page-details__header">Leak details</h1>
        <h4 className="part">Part 2 of 2</h4>
        <button
          className="add-new-item-button"
          type="button"
          onClick={createLeakRepair}
        >
          <IoAddOutline />
          Add new repair
        </button>
      </div>
      {leakTagOptions.length > 0 ? (
        <>
          <FormLabel>Were any leaks repaired on site?</FormLabel>
          <Toggle
            firstOption="Yes"
            secondOption="No"
            activeOption={leaksRepaired ? 'Yes' : 'No'}
            onToggle={handleToggle}
          />
        </>
      ) : <p>No leaks available to create a repair on.</p>}
      {
        leaksRepaired && leakRepairs.length > 0
        && leakRepairs.map((repair, index) => (
          <LeakRepairCard
            key={repair.leakRepairId}
            leakRepair={repair}
            deleteLeakRepair={() => deleteLeakRepair(repair)}
            leakTagOptions={leakTagOptions}
            repairNumber={index + 1}
          />
        ))
      }
      <button
        className="add-new-item-button-bottom"
        type="button"
        onClick={createLeakRepair}
      >
        <IoAddOutline />
        Add new repair
      </button>
    </div>
  );
};

export default LeakRepairs;
