import React, { useContext, useState } from 'react';
import { Dropdown, Toggle } from '@flogistix/flo-ui';

import { InspectionContext } from '../../../../context/InspectionContext';
import { ComplianceType, InspectionFrequency, OperationalStatus } from '../../../../classes/enums';
import RangerPicker from '../../../../components/RangePicker/RangePicker';
import FormLabel from '../../../../components/Shared/FormLabel/FormLabel';

const Info = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext) || {};
  const [searchFrequency, setSearchFrequency] = useState('');
  const [complianceType, setComplianceType] = useState(inspection?.complianceType ?? 'OOOO');
  const [operationalStatus, setOperationalStatus] = useState(inspection?.operationalStatus ?? 'Running');
  const [inspectionFrequency, setInspectionFrequency] = useState(inspection?.inspectionFrequency ?? undefined);

  const handleComplianceTypeToggle = () => {
    const existingComplianceType = complianceType.toUpperCase();
    const newComplianceType = existingComplianceType
    === ComplianceType.VOLUNTARY ? ComplianceType.OOOO : ComplianceType.VOLUNTARY;
    const newDisplayedComplianceType = newComplianceType === ComplianceType.VOLUNTARY ? 'Voluntary' : 'OOOO';
    if (inspection && token && inspection.complianceType !== newComplianceType) {
      inspection.updateComplianceType(token, newComplianceType);
    }
    setComplianceType(newDisplayedComplianceType);
    setCompletedSectionsOnChange();
  };

  const handleInspectionFrequencyChange = (selectedOption: { value: string | number, label: string }) => {
    const newInspectionFrequency = selectedOption.value as InspectionFrequency;
    if (inspection && token) {
      if (!newInspectionFrequency) {
        inspection.removeInspectionFrequency(token);
      } else if (inspection.inspectionFrequency !== newInspectionFrequency) {
        inspection.updateInspectionFrequency(token, newInspectionFrequency);
      }
    }
    setInspectionFrequency(newInspectionFrequency);
    setCompletedSectionsOnChange();
  };

  const handleToggleThree = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
    if ('key' in e && e.key !== 'Enter' && e.key !== ' ') {
      return;
    }
    const clientX = 'clientX' in e ? e.clientX : 0;
    const { width, left } = e.currentTarget.getBoundingClientRect();
    const clickX = clientX - left;

    let newStatus: OperationalStatus = OperationalStatus.OTHER;
    if (clickX < width * 0.33) {
      newStatus = OperationalStatus.RUNNING;
    } else if (clickX >= width * 0.33 && clickX < width * 0.66) {
      newStatus = OperationalStatus.DISABLED;
    }

    if (inspection && token && inspection.operationalStatus !== newStatus) {
      inspection.updateOperationalStatus(token, newStatus);
    }
    setOperationalStatus(newStatus);
    setCompletedSectionsOnChange();
  };

  return (
    <>
      <h1 className="page-details__header">Inspection details</h1>
      <h4 className="part">Part 1 of 4 - Information</h4>
      <FormLabel>Compliance type</FormLabel>
      <Toggle
        firstOption="OOOO"
        secondOption="Voluntary"
        activeOption={complianceType}
        onToggle={handleComplianceTypeToggle}
      />
      <FormLabel>Operational status</FormLabel>
      <div
        className="toggle-three"
        onClick={handleToggleThree}
        onKeyDown={handleToggleThree}
        role="button"
        tabIndex={0}
      >
        <div className={`toggle-three-circle ${operationalStatus?.toLowerCase()}`}>
          {operationalStatus}
        </div>
        <div className="placeholders">
          <div className={`placeholder ${operationalStatus === 'Running' ? 'running' : ''}`}>Running</div>
          <div className={`placeholder ${
            operationalStatus === 'Disabled' ? 'disabled' : ''
          } ${operationalStatus === 'Other' ? 'other' : ''} ${operationalStatus === 'Running' ? 'running' : ''}`}
          >
            Disabled
          </div>
          <div className={`placeholder ${operationalStatus === 'Other' ? 'other' : ''}`}>Other</div>
        </div>
      </div>
      <FormLabel>Inspection frequency</FormLabel>
      <Dropdown
        id="inspection-frequency"
        value={inspectionFrequency || ''}
        onSelect={(option) => handleInspectionFrequencyChange(option as { value: string | number, label: string })}
        onSearchChange={(value) => setSearchFrequency(value)}
        searchValue={searchFrequency}
        className="input__dropdown"
        options={Object.values(InspectionFrequency).map((frequency) => ({
          value: frequency,
          label: frequency
        }))}
        placeholder="Select frequency"
        fixedWidth="100%"
        onClear={() => handleInspectionFrequencyChange({ value: '', label: '' })}
      />
      <FormLabel>Inspection time</FormLabel>
      <div className="input__select">
        <RangerPicker />
      </div>
    </>
  );
};

export default Info;
