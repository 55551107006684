// eslint-disable-next-line import/no-cycle
import { Transaction } from '../dexie/db';
import { createTransaction } from '../dexie/operations';
import AirMethaneFile, { UploadFile } from './airmethane-file';
import { SurveyMethod } from './enums';
import { Inspection, Inspector } from './inspection';
import SurveyInstrument from './survey-instrument';
import { API_INSPECTION, urlPrefix } from '../shared/url';

export interface ISurvey {
  surveyId?: string;
  method?: SurveyMethod;
  surveyInstrument?: SurveyInstrument;
  inspectors?: Inspector[];
  inspectionDurationHours?: number;
  inspectionDurationMinutes?: number;
  altitude?: number;
  gimbalAngle?: number;
  files?: AirMethaneFile[];
}

export default class Survey {
  surveyId?: string;

  method?: SurveyMethod;

  surveyInstrument?: SurveyInstrument;

  inspectors?: Inspector[];

  inspectionDurationHours?: number;

  inspectionDurationMinutes?: number;

  altitude?: number;

  gimbalAngle?: number;

  files?: AirMethaneFile[];

  pendingFiles?: UploadFile[];

  constructor({
    surveyId,
    method,
    surveyInstrument,
    inspectors,
    inspectionDurationHours,
    inspectionDurationMinutes,
    altitude,
    gimbalAngle,
    files
  }: ISurvey = {}) {
    this.surveyId = surveyId;
    this.method = method;
    this.surveyInstrument = surveyInstrument;
    this.inspectors = inspectors;
    this.inspectionDurationHours = inspectionDurationHours;
    this.inspectionDurationMinutes = inspectionDurationMinutes;
    this.altitude = altitude;
    this.gimbalAngle = gimbalAngle;
    this.files = files;
  }

  async addFile(token: string, inspectionId: string, file: AirMethaneFile) {
    this.files?.push(file);
    const transaction = {
      queueId: `${this.surveyId}`,
      request: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: file as object,
        endpoint: `${API_INSPECTION}/${inspectionId}/surveys/${this.surveyId}/addFile`
      }
    } as Transaction;
    await createTransaction(transaction);
  }

  async removeFile(token: string, fileVersion: number, file: AirMethaneFile) {
    this.files = this.files?.filter((f) => f.id !== file.id);
    const deleteTransaction = {
      queueId: `${this.surveyId}`,
      request: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        endpoint: `https://${urlPrefix}files.api.axil.ai/files/${file?.id}`,
        payload: {},
        version: `${fileVersion}`,
        fileUpload: false
      }
    } as Transaction;
    await createTransaction(deleteTransaction);
  }

  async patchSurvey(token: string, flogistixId: string, inspection: Inspection, survey: Survey) {
    this.method = survey.method;
    this.surveyInstrument = survey.surveyInstrument;
    this.inspectors = survey.inspectors;
    this.inspectionDurationHours = survey.inspectionDurationHours;
    this.inspectionDurationMinutes = survey.inspectionDurationMinutes;
    this.altitude = survey.altitude;
    this.gimbalAngle = survey.gimbalAngle;
    this.files = survey.files ?? [];
    this.pendingFiles = survey.pendingFiles ?? [];

    const { pendingFiles, ...surveyObject } = survey;
    if (flogistixId) {
      const transaction = {
        queueId: `${flogistixId}`,
        inspectionId: inspection.id,
        request: {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {
            body: {
              ...surveyObject
            },
            pendingFiles,
            uploadedFiles: this.files,
            inspectionId: inspection.id
          },
          fileUpload: true,
          endpoint: `${API_INSPECTION}/${inspection.id}/patchSurvey`
        }
      } as Transaction;
      const queuedFiles = this.pendingFiles.map((file) => new AirMethaneFile(file.id));
      this.files = [...this.files, ...queuedFiles];
      this.pendingFiles = [];
      const newInspection = new Inspection(inspection);
      const index = newInspection?.surveys?.length
        ? newInspection.surveys.findIndex((item) => item.surveyId === this.surveyId)
        : -1;

      const newSurveys = newInspection.surveys?.filter((s) => s.surveyId !== this.surveyId);

      if (index === -1) {
        newInspection.surveys = newSurveys ? [...newSurveys, this] : [this];
      } else {
        newInspection.surveys = newSurveys ? [
          ...newSurveys.slice(0, index),
          this,
          ...newSurveys.slice(index)
        ] : [this];
      }
      await createTransaction(transaction, newInspection);
    }
  }

  getSubmissionReadiness() {
    return (
      this.surveyId !== undefined
      && this.method !== undefined
      && this.surveyInstrument !== undefined
      && this.files ? this.files.length > 0 : false
    );
  }

  getSurveyCompletionPercentage() {
    const requiredFields = 3;
    const existingFields = [
      this.method,
      this.surveyInstrument,
      (this.files ? this.files.length > 0 : null)
    ].filter((field) => !!field);
    return (existingFields.length / requiredFields) * 100;
  }

  getRemainingFields() {
    const remainingFields: string[] = [];
    if (!this.inspectors?.length) remainingFields.push('Inspectors');
    if (!this.method) remainingFields.push('Survey method');
    if (!this.surveyInstrument) remainingFields.push('Survey instrument');
    if (!this.files?.length) remainingFields.push('Survey files');
    return remainingFields;
  }
}
