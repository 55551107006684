import React, {
  useRef, useState, useEffect, useContext
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Icon, Modal, Spinner } from '@flogistix/flo-ui';

import { InspectionContext } from '../../../context/InspectionContext';
import './StatusDropzone.scss';
import { global } from '../../../shared/colors';
import { MAX_FILE_SIZE_IN_BYTES } from '../../../shared/constants';
import { triggerMaxFileSizeNotification } from '../../../shared/utils';
import { FileData, UploadFile } from '../../../classes/airmethane-file';
import { fetchFileUrl, fetchFilesForInspection } from '../../../services/airmethaneApi';
import { getInspectionPendingFiles } from '../../../dexie/operations';
import { GlobalContext } from '../../../context/GlobalContext';

interface StatusDropzoneProps {
  onFileChange: (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    files: UploadFile[]
  ) => void;
  acceptedTypes: string;
  removeHandler?: (fileId?: string) => void;
  fileIds: string[];
  disabled: boolean;
}

const StatusDropzone: React.FC<StatusDropzoneProps> = ({
  onFileChange,
  acceptedTypes,
  removeHandler,
  fileIds,
  disabled
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filesData, setFilesData] = useState<FileData[]>([]);
  const [processingFiles, setProcessingFiles] = useState<FileData[]>([]);
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  const { token, inspection, hasFileId } = useContext(InspectionContext);
  const { triggerGlobalNotification } = useContext(GlobalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFileIdToDelete, setCurrentFileIdToDelete] = useState<string | null>(null);

  const uploadFileToFileData = (files: UploadFile[]): FileData[] => {
    const newProcessingFiles = files.map((file) => ({
      id: file.id,
      parentId: inspection?.id ?? '',
      version: 0,
      createdAt: new Date().toISOString(),
      createdBy: '',
      updatedAt: '',
      updatedBy: '',
      deleted: false,
      lastAct: '',
      fileType: file.file.type,
      s3Key: '',
      fileUrl: '',
      name: file.file.name,
      displayName: file.displayName,
      extension: file.file.name.split('.').pop() ?? '',
      url: URL.createObjectURL(file.file),
      size: file.file.size,
      status: 'processing'
    }));
    return newProcessingFiles;
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (!disabled) {
      event.preventDefault();
    }
  };

  const handleClick = () => {
    if (!disabled) {
      fileInputRef.current?.click();
    }
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>, fileId: string) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentFileIdToDelete(fileId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!currentFileIdToDelete) return;

    const fileId = currentFileIdToDelete;

    setFilesData((prev) => prev.filter((file) => file.id !== fileId));
    setProcessingFiles((prev) => prev.filter((file) => file.id !== fileId));

    if (removeHandler) {
      try {
        removeHandler(fileId);
      } catch (error) {
        console.error('Error removing file:', error);
      }
    }

    setRemovedFiles((prev) => [...prev, fileId]);
    setIsModalOpen(false);
    setCurrentFileIdToDelete(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentFileIdToDelete(null);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    if (disabled) return;

    event.preventDefault();
    event.stopPropagation();

    let newFiles: File[] = [];
    const oversizedFiles: File[] = [];
    if (event.type === 'change') {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        newFiles = Array.from(target.files);
        newFiles = newFiles.filter((file) => {
          if (file.size > MAX_FILE_SIZE_IN_BYTES) {
            oversizedFiles.push(file);
            return false;
          }
          return true;
        });
      }
    } else if (event.type === 'drop') {
      const e = event as React.DragEvent<HTMLDivElement>;
      if (e.dataTransfer.files) {
        newFiles = Array.from(e.dataTransfer.files);
        newFiles = newFiles.filter((file) => {
          if (file.size > MAX_FILE_SIZE_IN_BYTES) {
            oversizedFiles.push(file);
            return false;
          }
          return true;
        });
      }
    }

    if (oversizedFiles.length > 0) {
      const oversizedFileNames = oversizedFiles.map((file) => file.name).join(', ');
      triggerMaxFileSizeNotification(triggerGlobalNotification, oversizedFileNames);
    }

    if (newFiles.length === 0) {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    const newFileData: UploadFile[] = newFiles.map((file) => ({
      id: uuidv4(),
      file,
      displayName: file.name,
      status: 'processing'
    }));

    const newProcessingFiles = uploadFileToFileData(newFileData);

    setProcessingFiles((prev) => [...prev, ...newProcessingFiles]);

    onFileChange(event, newFileData);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'done':
        return 'done';
      case 'processing':
        return 'processing';
      case 'failed':
        return 'failed';
      default:
        return '';
    }
  };

  const handleSingleDownload = async (e: React.MouseEvent<HTMLButtonElement>, fileId: string) => {
    setDownloading(true);
    e.stopPropagation();
    e.preventDefault();

    try {
      const result = await fetchFileUrl(fileId, token);

      if (result?.url) {
        const response = await fetch(result.url);
        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = result.name || 'downloadedFile';
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(downloadUrl);
      } else {
        console.error('File not found or download failed');
      }
      setDownloading(false);
    } catch (error) {
      console.error('Error downloading file:', error);
      setDownloading(false);
    }
  };

  const fetchInspectionFiles = async () => {
    if (inspection?.id && token) {
      const files = await fetchFilesForInspection(inspection?.id, token);

      if (files) {
        setFilesData(files.map((file: FileData) => ({
          ...file,
          status: file.s3Key ? 'done' : 'processing'
        })));
      }
    } else {
      setFilesData([]);
    }
  };

  useEffect(() => {
    const fetchPendingFiles = async () => {
      if (inspection?.id) {
        const files = await getInspectionPendingFiles(inspection.id);
        setProcessingFiles(uploadFileToFileData(files));
      }
    };
    fetchPendingFiles();
    fetchInspectionFiles();
  }, [inspection?.id]);

  useEffect(() => {
    const channel = new BroadcastChannel('upload-status-channel');

    channel.onmessage = async (event) => {
      if (event.data.type === 'UPLOAD_STATUS') {
        const { fileId, status } = event.data;
        setProcessingFiles((prev) => prev.map((file) => (file.id === fileId ? { ...file, status } : file)));
        await fetchInspectionFiles();
      }
    };
    return () => {
      channel.close();
    };
  }, [fileIds, token, removedFiles]);

  const mergedFilesData = [
    ...filesData,
    ...processingFiles.filter((file) => !filesData.some((f) => f.id === file.id))
  ];

  return (
    <div className={`status-dropzone-container ${disabled ? 'disabled' : ''}`}>
      <div
        className={`status-file-dropzone ${disabled ? 'disabled' : ''}`}
        onDrop={handleFileChange}
        onDragOver={handleDragOver}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleClick();
          }
        }}
        role="button"
        tabIndex={0}
      >
        {mergedFilesData.length === 0 && (
          <section id="file-upload" className="click-to-section">
            <span className={disabled ? 'disabled-text' : 'click-text'}>Click to upload </span>
            {' '}
            <p>or drag and drop</p>
          </section>
        )}
        <input
          type="file"
          accept={acceptedTypes}
          onChange={handleFileChange}
          ref={fileInputRef}
          multiple
          disabled={disabled}
        />
        {mergedFilesData.length > 0 && (
          <div className="file-table-container">
            <table className="file-table">
              <thead>
                <tr>
                  <th style={{ width: '40%' }}>File name</th>
                  <th style={{ width: '20%' }}>File size</th>
                  <th style={{ width: '40%' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {mergedFilesData.map((file) => (
                  <tr key={file.id}>
                    <td>
                      <a href={file.url} target="_blank" rel="noopener noreferrer" title={file.displayName}>
                        {file.displayName}
                      </a>
                    </td>
                    <td>
                      {(file.size / 1024).toFixed(2)}
                      {' '}
                      KB
                    </td>
                    <td>
                      <div className="status-column">
                        <span className={`status-processing-text ${getStatusClass(file?.status)}`}>
                          {file.status}
                        </span>
                        <span className="flex-container">
                          {file.status === 'done' && (
                            <>
                              { !downloading && (
                                <button
                                  className="status-file-removal-button"
                                  type="button"
                                  onClick={(e) => handleSingleDownload(e, file.id)}
                                  aria-label={`Download ${file.displayName}`}
                                >
                                  <Icon iconName="Download" color={global?.Gray9} height="16" width="16" />
                                </button>
                              )}
                              { downloading && (
                                <Spinner size="16px" />
                              )}
                              <button
                                className="status-file-removal-button"
                                type="button"
                                onClick={(e) => handleDeleteClick(e, file.id)}
                                disabled={hasFileId(file.id)}
                              >
                                <Icon iconName="Trash" color={global?.Gray9} height="16" width="16" />
                              </button>
                            </>
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {mergedFilesData.length > 0 && (
        <button
          type="button"
          className="upload-more-button"
          onClick={handleClick}
          disabled={disabled}
        >
          Upload more media
        </button>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Are you sure?"
        subtitle="Are you sure you would like to delete this file?"
        actions={{
          onCancel: handleCloseModal,
          onConfirm: handleConfirmDelete,
          loading: false
        }}
        variant="delete"
        className="submission-modal"
      />
    </div>
  );
};

StatusDropzone.defaultProps = {
  removeHandler: undefined
};

export default StatusDropzone;
